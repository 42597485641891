import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

// Styles
import styles from "./styles.css";

// components
import Login from "../login";

// hooks
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";

// images
import RightArrowImg from "./images/right-arrow.svg";
import { NUMBER } from "../../../constants/app-constants";

const ExclusiveBannersSection = ({
    superAppConfig,
    isLoggedIn
}) => {

    const { exclusiveOffers } = superAppConfig || {};

    const { customPush } = useCustomHistoryOperations();

    const [showLogin, setShowLogin] = useState(false);
    const ctaDataRef = useRef();

    const onBannerCtaClick = async (ctaData) => {
        ctaDataRef.current = ctaData;
        const { path, authRequired } = ctaData || {};
        if (authRequired && !isLoggedIn) {
            setShowLogin(true);
        } else {
            await customPush(path);
        }

    };

    const getFormattedText = (text = "") => {
        const formatted = (text || "").replace(/\n/g, "<br />");
        return formatted;
    };

    return (
        <Login
            isVisible={showLogin}
            onSuccess={() => { customPush(ctaDataRef?.current?.path || "/"); }}
            onClose={() => { setShowLogin(false); }}
        >
            <div styleName={"styles.exclusiveBannersWrapper"} >
                <div styleName={"styles.titleWrapper"} >
                    <div styleName={"styles.greyLineleft"} />
                    <div styleName={"styles.title"} >Exclusive offers for you</div>
                    <div styleName={"styles.greyLineRight"} />
                </div>
                {
                    (exclusiveOffers || []).length > NUMBER.ZERO ?
                        <div styleName={"styles.sliderWrap"} >
                            {(exclusiveOffers || []).map((offerItem, index) => {
                                const { bgImage, backgroundColor, title, subTitle, badge, cta } = offerItem || {};
                                return (
                                    <div key={`${index}`} >
                                        {index < NUMBER.ONE && <Helmet>
                                            <link rel="preload" href={bgImage} as={"image"} type="image/png" fetchPriority="high" />
                                        </Helmet>}
                                        <div styleName={"styles.exbannerWrap"}
                                            style={{
                                                backgroundColor,
                                                backgroundSize: "cover",
                                                backgroundPosition: "center",
                                                backgroundRepeat: "no-repeat"
                                            }}
                                        >
                                            <div styleName={"styles.imageWrap"} >
                                                <img styleName={"styles.backgroundBaner"} src={bgImage} loading={index === 0 ? "eager" : "lazy"} />
                                                <div styleName={"styles.infoWrap"}>
                                                    <div styleName={"styles.badgeWrap"}
                                                        style={{
                                                            color: badge.color,
                                                            background: `linear-gradient(90deg, ${badge.gradient[0]}, ${badge.gradient[1]})`
                                                        }}
                                                    >
                                                        {badge.text}
                                                    </div>
                                                    <div styleName={"styles.offerTitleWrap"} >
                                                        <p
                                                            styleName={"styles.title"}
                                                            style={{ color: title.color }}
                                                            dangerouslySetInnerHTML={{ __html: getFormattedText(title.text) }}
                                                        />
                                                        <p
                                                            styleName={"styles.subTitle"}
                                                            style={{ color: subTitle.color }}
                                                            dangerouslySetInnerHTML={{ __html: getFormattedText(subTitle.text) }}
                                                        />
                                                    </div>
                                                    <div styleName={"styles.ctaWrap"}  >
                                                        <button onClick={() => { onBannerCtaClick(cta); }} >
                                                            {cta.title} <img src={RightArrowImg} />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        :
                        <React.Fragment />
                }

            </div>
        </Login>
    );
};

ExclusiveBannersSection.propTypes = {
    superAppConfig: PropTypes.object,
    isLoggedIn: PropTypes.bool
};

export default ExclusiveBannersSection;

