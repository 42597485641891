import React from "react";
import PropTypes from "prop-types";

// Styles
import styles from "./styles.css";

// hooks
import useCustomHistoryOperations from "../../../hooks/use-custom-history-operations";

// images
import RightArrowImg from "./images/right-arrow.svg";
import { NUMBER } from "../../../constants/app-constants";

// components
import LazyImage from "../../shared/lazy-image";
import SlickWrapper from "../../shared/slick-wrapper";

const ExclusiveBannersSection = ({
    sectionInfo
}) => {

    const { title = "", sectionData = [] } = sectionInfo || {};

    const SLIDER_SETTINGS = {
        slidesToShow: 3.2,
        slidesToScroll: 1,
        arrow: true,
        nav: false,
        infinite: false
    };

    const { customPush } = useCustomHistoryOperations();

    const onBannerCtaClick = async (redirectionUrl) => {
        await customPush(redirectionUrl);
    };

    const getFormattedText = (text = "") => {
        const formatted = (text || "").replace(/\n/g, "<br />");
        return formatted;
    };

    return (
        <div styleName={"styles.exclusiveBannersWrapper"}  >
            {title && <div styleName={"styles.heading"} className="container" >
                {title}
            </div>}
            {
                (sectionData || []).length > NUMBER.ZERO ?
                    <div styleName={"styles.sliderWrap"} className="container" >
                        <SlickWrapper {...SLIDER_SETTINGS}>
                        {(sectionData || []).map((offerItem, index) => {
                            const {
                                image = {},
                                title: bannerTitle = "",
                                subTitle: bannerSubTitle = "",
                                primaryCtaUrl = "",
                                primaryCtaText = ""
                            } = offerItem || {};

                            const {
                                url: bannerUrl = "",
                                alternativeText: bannerImageAltText = ""
                            } = image || {};
                            return (
                                <div key={`${index}`} >
                                    <div styleName={"styles.exbannerWrap"}>
                                        <div styleName={"styles.imageWrap"} >
                                            {bannerUrl && <LazyImage styleName={"styles.backgroundBaner"} src={bannerUrl} alt={bannerImageAltText} />}
                                            <div styleName={"styles.infoWrap"}>
                                                <div styleName={"styles.offerTitleWrap"} >
                                                    {bannerTitle && <p
                                                        styleName={"styles.title"}
                                                        dangerouslySetInnerHTML={{ __html: getFormattedText(bannerTitle)?.replace(/\\n/g, "<br/>")  }}
                                                    />}
                                                    {bannerSubTitle && <p
                                                        styleName={"styles.subTitle"}
                                                        dangerouslySetInnerHTML={{ __html: getFormattedText(bannerSubTitle)?.replace(/\\n/g, "<br/>")  }}
                                                    />}
                                                </div>
                                                {primaryCtaText && <div styleName={"styles.ctaWrap"}  >
                                                    <button onClick={() => { onBannerCtaClick(primaryCtaUrl); }} >
                                                        {primaryCtaText} <img src={RightArrowImg} />
                                                    </button>
                                                </div>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                        </SlickWrapper>
                    </div>
                    :
                    <React.Fragment />
            }

        </div>

    );
};

ExclusiveBannersSection.propTypes = {
    sectionInfo: PropTypes.object
};

export default ExclusiveBannersSection;

